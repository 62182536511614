import React, { useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import './FloatingContactIcon.css';
import { ToastContainer, toast } from '../../toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { ReactComponent as CurvyArrow } from '../../CurvyArrow.svg';




const FloatingContactIcon = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [requestType, setRequestType] = useState('New Project');
  const [existingProjectType, setExistingProjectType] = useState('Bug Fix');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [projectUrl, setProjectUrl] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [projectTimeline, setProjectTimeline] = useState('');
  const [projectBudget, setProjectBudget] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [urlValid, setUrlValid] = useState(true);
  const [emailError, setEmailError] = useState(false);
    const [urlError, setUrlError] = useState(false);

    const handleEmailChange = (value) => {
    setEmail(value);
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!emailValid);
    };

    const handleEmailBlur = (value) => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!emailValid);
    };

    const handleUrlChange = (value) => {
    setProjectUrl(value);
    const urlValid = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/.test(value);
    setUrlError(!urlValid);
    };

    const handleUrlBlur = (value) => {
    const urlValid = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/.test(value);
    setUrlError(!urlValid);
    };



  

const openModal = () => {
  setModalVisible(true);
};
    
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};



    
const contactIconRef = useRef(null);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sanitize input
    const sanitizedName = DOMPurify.sanitize(name);
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedProjectUrl = DOMPurify.sanitize(projectUrl);
    const sanitizedProjectDetails = DOMPurify.sanitize(projectDetails);
    const sanitizedProjectTimeline = DOMPurify.sanitize(projectTimeline);
    const sanitizedProjectBudget = DOMPurify.sanitize(projectBudget);

    // Input validation
    if (!sanitizedName || !sanitizedEmail || !sanitizedProjectDetails) {
        toast.error('Please fill in all required fields.');
        return;
    }

    // Send form data to the email server
    setIsSubmitting(true);
    try {
        const response = await fetch('https://www.paulanigbo.com/ems/send-email.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            email: sanitizedEmail,
            requestType,
            projectUrl: sanitizedProjectUrl,
            existingProjectType,
            projectDetails: sanitizedProjectDetails,
            projectTimeline: sanitizedProjectTimeline,
            projectBudget: sanitizedProjectBudget,
        }),
        });

        if (response.ok) {
        toast.success('Your message has been sent successfully!');
        closeModal();
        } else {
        const errorData = await response.json();
        console.error('Error from email server:', errorData);
        toast.error('Failed to send your message. Please try again later.');
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to send your message. Please try again later.');
    } finally {
        setIsSubmitting(false);
        }



        closeModal();
        };

        return (
        <>
        <ToastContainer />
        <div onClick={openModal}>
            <div className="contact-icon contact-icon-pulse contact-icon-container">
            <CurvyArrow
                className="curvy-arrow"
                style={{ width: '20px', height: '20px', margin: '10px' }}
            />
            <div className="say-hello">Say Hello</div>
            {/* Replace with your desired contact icon */}
            <i className="fas fa-envelope"></i>
            </div>

            {modalVisible && (
            <div className="contact-modal" onClick={closeModal}>
                <div
                className="contact-modal-content"
                onClick={(e) => e.stopPropagation()}
                >
                <span className="close" onClick={closeModal}>
                    &times;
                </span>
                <h2 className="contactHeading">Glad you're Here!</h2>
                <p className="contact-para">How Can I help you? :-)</p>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    />

                    
                    <label htmlFor="email">Email:</label>
                    <Tippy content={emailError ? 'Invalid email address' : ''} visible={emailError}>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        onBlur={(e) => handleEmailBlur(e.target.value)}
                        required
                    />
                    </Tippy>

                    <label htmlFor="requestType">Request Type:</label>
                    <select
                    id="requestType"
                    name="requestType"
                    value={requestType}
                    onChange={(e) => setRequestType(e.target.value)}
                    >
                    <option value="New Project">New Project</option>
                    <option value="Existing Project">Existing Project</option>
                    </select>

                    {requestType === 'Existing Project' && (
                    <>
                        <label htmlFor="projectUrl">Project/site URL:</label>
                        <Tippy content={urlError ? 'Please enter a valid URL.' : ''} visible={urlError}>
                        <input
                            type="url"
                            id="projectUrl"
                            name="projectUrl"
                            value={projectUrl}
                            onChange={(e) => handleUrlChange(e.target.value)}
                            onBlur={(e) => handleUrlBlur(e.target.value)}
                            required
                        />
                        </Tippy>


                        <label htmlFor="existingProjectType">What do you need?</label>
                        <select
                        id="existingProjectType"
                        name="existingProjectType"
                        value={existingProjectType}
                        onChange={(e) => setExistingProjectType(e.target.value)}
                        >
                        <option value="Bug Fix">Bug Fix</option>
                        <option value="New Feature">New Feature</option>
                        </select>
                    </>
                    )}

                    <label htmlFor="projectDetails">Project Details:</label>
                    <textarea
                    id="projectDetails"
                    name="projectDetails"
                    rows="5"
                    value={projectDetails}
                    onChange={(e) => setProjectDetails(e.target.value)}
                    required
                    />

                    {requestType === 'New Project' && (
                    <>
                        <label htmlFor="projectTimeline">Project Timeline:</label>
                        <input
                        type="text"
                        id="projectTimeline"
                        name="projectTimeline"
                        value={projectTimeline}
                        onChange={(e) => setProjectTimeline(e.target.value)}
                        required
                        />

                        <label htmlFor="projectBudget">Project Budget:</label>
                                        <input
                  type="text"
                  id="projectBudget"
                  name="projectBudget"
                  value={projectBudget}
                  onChange={(e) => setProjectBudget(e.target.value)}
                  required
                />
              </>
            )}

            <div className="formButtonGroup">
                <button type="submit" className="send-btn">
                {isSubmitting && <div className="loader"></div>}
                 {!isSubmitting && <span>Send</span> }
            </button>

            <button type="button" className="cancel-btn" onClick={closeModal}>
                Cancel
            </button>
            </div>

          </form>
        </div>
      </div>
     )}
    </div>

  </>
);

};

export default FloatingContactIcon;


